const CHANGE_POSTCODE = 'CHANGE_POSTCODE';
const CHANGE_ADDRESS_LINE_1 = 'CHANGE_ADDRESS_LINE_1';
const CHANGE_ADDRESS_LINE_2 = 'CHANGE_ADDRESS_LINE_2';
const CHANGE_COUNTY = 'CHANGE_COUNTY';
const CHANGE_CITY = 'CHANGE_CITY';
const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
const SET_DETAILS_FROM_SEARCH_RESULT = 'SET_DETAILS_FROM_SEARCH_RESULT';
const CHANGE_ADDRESS_DETAILS_VALIDATION_ENABLED =
  'CHANGE_ADDRESS_DETAILS_VALIDATION_ENABLED';
const REPOPULATE_STATE = 'repopulate_state';

export const selectAddressDetails = state => state.addressDetails;

export const doChangePostcode = postcode => ({
  type: CHANGE_POSTCODE,
  postcode,
});

export const doChangeAddressLine1 = addressLine1 => ({
  type: CHANGE_ADDRESS_LINE_1,
  addressLine1,
});

export const doChangeAddressLine2 = addressLine2 => ({
  type: CHANGE_ADDRESS_LINE_2,
  addressLine2,
});

export const doChangeCounty = county => ({
  type: CHANGE_COUNTY,
  county,
});

export const doChangeCity = city => ({
  type: CHANGE_CITY,
  city,
});

export const doSetSearchResults = searchResults => ({
  type: SET_SEARCH_RESULTS,
  searchResults,
});

export const doChangeValidationEnabled = isValidationEnabled => ({
  type: CHANGE_ADDRESS_DETAILS_VALIDATION_ENABLED,
  isValidationEnabled,
});

export const doSetDetailsFromSearchResult = ({
  postcode = '',
  addressLine1 = '',
  addressLine2 = '',
  county = '',
  city = '',
}) => ({
  type: SET_DETAILS_FROM_SEARCH_RESULT,
  postcode,
  addressLine1,
  addressLine2,
  county,
  city,
});

function applyChangePostcode(state, action) {
  const { postcode } = action;

  return {
    ...state,
    postcode,
    isPostcodeValid: postcode !== '',
  };
}

function applyChangeAddressLine1(state, action) {
  const { addressLine1 } = action;
  return {
    ...state,
    addressLine1,
    isAddressLine1Valid: addressLine1 !== '',
  };
}

function applyChangeAddressLine2(state, action) {
  const { addressLine2 } = action;
  return {
    ...state,
    addressLine2,
    isAddressLine2Valid: addressLine2 !== '',
  };
}

function applyChangeCounty(state, action) {
  const { county } = action;

  return {
    ...state,
    county,
    isCountyValid: county !== '',
  };
}

function applyChangeCity(state, action) {
  const { city } = action;

  return {
    ...state,
    city,
    isCityValid: city !== '',
  };
}

function applySetSearchResults(state, action) {
  const { searchResults } = action;

  return {
    ...state,
    searchResults,
  };
}

function applyChangeAddressDetailsValidationEnabled(state, action) {
  const { isValidationEnabled } = action;

  return {
    ...state,
    isValidationEnabled,
  };
}

function applySetDetailsFromSearchResult(state, action) {
  const {
    postcode,
    addressLine1,
    addressLine2,
    county,
    city,
    searchResults,
  } = action;

  const countyValue = county || '';

  return {
    ...state,
    postcode,
    isPostcodeValid: postcode !== '',
    addressLine1,
    isAddressLine1Valid: addressLine1 !== '',
    addressLine2,
    isAddressLine2Valid: addressLine2 !== '',
    county: countyValue,
    isCountyValid: county !== '',
    city,
    isCityValid: city !== '',
    searchResults,
  };
}

const INITIAL_STATE = {
  postcode: '',
  isPostcodeValid: false,
  addressLine1: '',
  isAddressLine1Valid: false,
  addressLine2: '',
  isAddressLine2Valid: false,
  county: '',
  isCountyValid: false,
  city: '',
  isCityValid: false,
  searchResults: null,
  isValidationEnabled: false,
};

function repopulateAddressDetails(state, action) {
  const { address } = action.data.policy.policyHolder;
  return {
    ...state,
    postcode: address.postcode,
    isPostcodeValid: true,
    addressLine1: address.line1,
    isAddressLine1Valid: true,
    addressLine2: address.line2,
    isAddressLine2Valid: true,
    county: address.county,
    isCountyValid: true,
    city: address.town,
    isCityValid: true,
  };
}

function reducer(state = INITIAL_STATE, action) {
  const { type } = action;
  switch (type) {
    case CHANGE_POSTCODE:
      return applyChangePostcode(state, action);
    case CHANGE_ADDRESS_LINE_1:
      return applyChangeAddressLine1(state, action);
    case CHANGE_ADDRESS_LINE_2:
      return applyChangeAddressLine2(state, action);
    case CHANGE_COUNTY:
      return applyChangeCounty(state, action);
    case CHANGE_CITY:
      return applyChangeCity(state, action);
    case SET_SEARCH_RESULTS:
      return applySetSearchResults(state, action);
    case CHANGE_ADDRESS_DETAILS_VALIDATION_ENABLED:
      return applyChangeAddressDetailsValidationEnabled(state, action);
    case SET_DETAILS_FROM_SEARCH_RESULT:
      return applySetDetailsFromSearchResult(state, action);
    case REPOPULATE_STATE:
      return repopulateAddressDetails(state, action);
    default:
      return state;
  }
}

export default reducer;
