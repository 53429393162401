export const GET_LOOKUPS = 'getLookups';
export const GET_ADDRESS_AUTOCOMPLETE = 'getAddressAutocomplete';
export const GET_ADDRESS_DETAILS = 'getAddressDetails';
export const RATE_QUOTE = 'rateQuote';
export const REPRICE_QUOTE = 'repriceQuote';
export const SAVE_QUOTE = 'saveQuote';
export const SAVE_MTA = 'saveMTA';
export const RATE_MTA = 'rateMTA';
export const CREATE_INITIAL_MTA = 'createInitialMTA';
export const SAVE_QUOTE_PERKBOX = 'saveQuotePerkbox';
export const SAVE_POLICY_MYDENTIST = 'savePolicyMyDentist';
export const CLAIM_QUOTE = 'claimQuote';
export const SAVE_POLICY = 'savePolicy';
export const GET_POLICY = 'getPolicy';
export const CLAIM_POLICY = 'claimPolicy';
export const CHECKPOLICYCLAIMSTATUS = 'checkPolicyClaimStatus';
export const GET_USER_POLICIES = 'getUserPolicies';
export const UPDATE_USER_QUOTE = 'updateUserQuote';
export const GET_USER_QUOTE = 'getUserQuote';
export const GET_USER_QUOTES = 'getUserQuotes';
export const DOWNLOAD_POLICY_DOCUMENT = 'downloadPolicyDocument';
export const GET_PERKBOX_POLICIES = 'getPerkboxPolicies';
export const TAKE_UP_MTA = 'takeUpMTA';
export const CREATE_MTA_PERKBOX = 'createMTAPerkbox';
export const CONFIRM_EMAIL = 'confirmEmail';
export const SEND_EMAIL_CONFIRMATION = 'sendEmailConfirmation';
export const GENERATE_POST_SIGNUP_MTA = 'generatePostSignupMTA';
export const CREATE_USER = 'createUserAndSendVerifyEmail';
