import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import * as COLLECTIONS from '../constants/collections';
import * as FUNCTIONS from '../constants/functions';
import * as POLICY_DOCUMENTS from '../constants/documentTypes';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  signUpRedirectUrl: process.env.REACT_APP_FIREBASE_CONFIRMATION_EMAIL_REDIRECT,
  cloudFunctionsBaseUrl:
  process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_BASE_URL,
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    if (process.env.REACT_APP_USE_LOCAL_FIREBASE) {
      firebase.functions().useFunctionsEmulator('http://localhost:5000');
    }

    this.firestore = firebase.firestore();
    this.auth = firebase.auth();
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.getLookups = firebase.functions().httpsCallable(FUNCTIONS.GET_LOOKUPS);
    this.getAddressAutocomplete = firebase
      .functions()
      .httpsCallable(FUNCTIONS.GET_ADDRESS_AUTOCOMPLETE);
    this.getAddressDetails = firebase
      .functions()
      .httpsCallable(FUNCTIONS.GET_ADDRESS_DETAILS);
    this.rateQuote = firebase.functions().httpsCallable(FUNCTIONS.RATE_QUOTE);
    this.repriceQuote = firebase.functions().httpsCallable(FUNCTIONS.REPRICE_QUOTE);
    this.saveQuote = firebase.functions().httpsCallable(FUNCTIONS.SAVE_QUOTE);
    this.sendEmailConfirmation = firebase.functions().httpsCallable(FUNCTIONS.SEND_EMAIL_CONFIRMATION);
    this.getPolicy = firebase.functions().httpsCallable(FUNCTIONS.GET_POLICY);
    this.saveQuotePerkbox = firebase.functions().httpsCallable(FUNCTIONS.SAVE_QUOTE_PERKBOX);
    this.savePolicyMyDentist = firebase.functions().httpsCallable(FUNCTIONS.SAVE_POLICY_MYDENTIST);
    this.claimQuote = firebase.functions().httpsCallable(FUNCTIONS.CLAIM_QUOTE);
    this.generatePostSignupMTA = firebase.functions().httpsCallable(FUNCTIONS.GENERATE_POST_SIGNUP_MTA);
    this.savePolicy = firebase.functions().httpsCallable(FUNCTIONS.SAVE_POLICY);
    this.saveMTA = firebase.functions().httpsCallable(FUNCTIONS.SAVE_MTA);
    this.rateMTA = firebase.functions().httpsCallable(FUNCTIONS.RATE_MTA);
    this.createInitialMTA = firebase.functions().httpsCallable(FUNCTIONS.CREATE_INITIAL_MTA);
    this.takeUpMTA = firebase.functions().httpsCallable(FUNCTIONS.TAKE_UP_MTA);
    this.createUserAndSendVerifyEmail = firebase.functions().httpsCallable(FUNCTIONS.CREATE_USER);
    this.createMTAPerkbox = firebase.functions().httpsCallable(FUNCTIONS.CREATE_MTA_PERKBOX);
    this.confirmEmail = firebase.functions().httpsCallable(FUNCTIONS.CONFIRM_EMAIL);
    this.claimPolicy = firebase
      .functions()
      .httpsCallable(FUNCTIONS.CLAIM_POLICY);
    this.checkPolicyClaimStatus = firebase
      .functions()
      .httpsCallable(FUNCTIONS.CHECKPOLICYCLAIMSTATUS);
    this.doGetUserQuote = firebase
      .functions()
      .httpsCallable(FUNCTIONS.GET_USER_QUOTE);
    this.updateUserQuote = firebase
      .functions()
      .httpsCallable(FUNCTIONS.UPDATE_USER_QUOTE);
    this.getUserQuotes = firebase
      .functions()
      .httpsCallable(FUNCTIONS.GET_USER_QUOTES);
    this.getUserPolicies = firebase
      .functions()
      .httpsCallable(FUNCTIONS.GET_USER_POLICIES);
    this.getPerkboxPolicies = firebase
      .functions()
      .httpsCallable(FUNCTIONS.GET_PERKBOX_POLICIES);
    this.downloadPolicyDocument = firebase
      .functions()
      .httpsCallable(FUNCTIONS.DOWNLOAD_POLICY_DOCUMENT);
  }

  doGetLookups = async product => {
    const rsp = await this.getLookups({ product });
    return rsp.data;
  };

  doGetAddressAutocomplete = async searchTerm => {
    const rsp = await this.getAddressAutocomplete({ searchTerm });
    const data = rsp.data.filter(d => !!d);
    if (data.length === 0) {
      return null;
    }
    return data;
  };

  doGetAddressDetails = async id => {
    const rsp = await this.getAddressDetails({ id });
    return rsp.data;
  };

  doRateQuote = async (request, quoteRef) => {
    const rsp = await this.rateQuote({ request, quoteRef });
    return rsp.data;
  };

  doRepriceQuote = async (request, quoteRef, underwritingResponseId) => {
    const rsp = await this.repriceQuote({ request, quoteRef, underwritingResponseId });
    return rsp.data;
  };

  doSaveQuote = async (quote, id) => {
    const rsp = await this.saveQuote({ quote, id });

    return rsp.data;
  };

  doSaveQuotePerkbox = async (request) => {
    const rsp = await this.saveQuotePerkbox(request);
    return rsp.data;
  };

  doSavePolicyMyDentist = async (request) => {
    return await this.savePolicyMyDentist({ request });
  };


  doSendEmailConfirmation = async (request) => {
    await this.sendEmailConfirmation(request);
  };

  doClaimQuote = async id => {
    await this.claimQuote({ id });
  };

  doSavePolicy = async request => {
    const rsp = await this.savePolicy(request);
    return rsp.data;
  };

  doSaveMTA = async request => {
    await this.saveMTA(request);
  };

  doRateMTA = async request => {
    return await this.rateMTA(request);
  };

  doCreateInitialMTA = async request => {
    return await this.createInitialMTA(request);
  };

  doCreateMTAPerkbox = async request => {
    return await this.createMTAPerkbox(request);
  };


  doTakeUpMTA = async (policyReference, mtaReference) => {
    return await this.takeUpMTA({ policyReference, mtaReference });
  };

  doGeneratePostSignupMTA = async () => {
    return await this.generatePostSignupMTA();
  };

  doSignInWithGoogle = async () => {
    await this.auth.signInWithPopup(this.googleProvider);
  };

  doSignOut = async () => {
    await this.auth.signOut();
  };

  doSignInWithEmailAndPassword = async (email, password) => {
    await this.auth.signInWithEmailAndPassword(email, password);
  };

  doGetUserQuotes = async () => {
    const { uid } = this.auth.currentUser;

    const quotesSnapshot = await this.firestore
      .collection(COLLECTIONS.USERS)
      .doc(uid)
      .collection(COLLECTIONS.QUOTES)
      .get();
    return quotesSnapshot.docs.map(s => ({ id: s.id, quote: s.data() }));
  };

  doGetUserPolicies = async () => {
    const { data } = await this.getUserPolicies();
    return data;
  };

  doGetPerkboxPolicies = async () => {
    const { data } = await this.getPerkboxPolicies();
    return data;
  };

  doConfirmEmail = async (hash) => {
    return await this.confirmEmail({ hash });
  };

  doGetClaimedUserQuotes = async () => {
    const { data } = await this.getUserQuotes();
    return data;
  };

  doGetUserQuote = async ({ quoteId, userId }) => {
    const { data } = await this.doGetUserQuote({ quoteId, userId });
    return data;
  };

  doGetPolicy = async (policyReference) => {
    const { data } = await this.getPolicy({ policyReference });
    return data;
  };

  doClaimPolicy = async id => {
    const { data } = await this.claimPolicy({ id });
    return data;
  };

  doCheckPolicyClaimStatus = async id => {
    const { data } = await this.checkPolicyClaimStatus({ id });
    return data;
  }

  doSendPasswordResetEmail = async emailAddress => {
    await this.auth.sendPasswordResetEmail(emailAddress);
  };

  doSignUp = async (email, password, service) => {
    const { user } = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    await this.createUserAndSendVerifyEmail({
      user: { displayName: user.displayName, email: user.email, photoURL: user.photoURL, uid: user.uid },
      additionalData: { displayName: '' },
      service
    });
  };

  // TODO: move to cloud function
  doCreateUserDocument = async (user, additionalData) => {
    // If there is no user, let's not do this.
    if (!user) return;

    // Get a reference to the location in the Firestore where the user
    // document may or may not exist.
    const userRef = this.firestore.doc(`${COLLECTIONS.USERS}/${user.uid}`);

    // Go and fetch a document from that location.
    const snapshot = await userRef.get();

    // If there isn't a document for that user. Let's use information
    // that we got from either Google or our sign up form.
    if (!snapshot.exists) {
      const { displayName, email, photoURL } = user;
      const createdAt = new Date();
      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          createdAt,
          ...additionalData,
        });
      } catch (error) {
        console.error('Error creating user', console.error);
      }
    }

    // Get the document and return it, since that's what we're
    // likely to want to do next.
    return this.doGetUserDocument(user.uid);
  };

  doGetUserDocument = async uid => {
    if (!uid) return null;

    return this.firestore.collection(COLLECTIONS.USERS).doc(uid);
  };

  doDownloadPolicySummaryDocument = async (id, perkboxPolicyReference) => {
    const { data } = await this.downloadPolicyDocument({
      id,
      perkboxPolicyReference,
      docType: POLICY_DOCUMENTS.SUMMARY,
    });
    return data;
  };

  doDownloadPolicyWordingDocument = async (id, perkboxPolicyReference) => {
    const { data } = await this.downloadPolicyDocument({
      id,
      perkboxPolicyReference,
      docType: POLICY_DOCUMENTS.WORDING,
    });
    return data;
  };

  doDownloadPolicyScheduleDocument = async (id, perkboxPolicyReference) => {
    const { data } = await this.downloadPolicyDocument({
      id,
      perkboxPolicyReference,
      docType: POLICY_DOCUMENTS.SCHEDULE,
    });
    return data;
  };

  doDownloadPolicyMTADocument = async (id, perkboxPolicyReference) => {
    const { data } = await this.downloadPolicyDocument({
      id,
      perkboxPolicyReference,
      docType: POLICY_DOCUMENTS.MTA,
    });
    return data;
  };

  doDownloadPolicyIPIDDocument = async (id, perkboxPolicyReference) => {
    const { data } = await this.downloadPolicyDocument({
      id,
      perkboxPolicyReference,
      docType: POLICY_DOCUMENTS.IPID,
    });
    return data;
  };
}

export default Firebase;
