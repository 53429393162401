import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useAsync } from 'react-use';
import { parse } from 'qs';

import YourPhone from '../YourPhone';
import YourDetails from '../YourDetails';
import LoadingSpinner from '../LoadingSpinner';
import YourQuote from '../YourQuote';
import Footer from '../Footer';
import { ProgressIndicator } from '../components/ProgressIndicator';

import mobiruLogo from '../../assets/mobiru-gadget-logo.png';
import perkboxLogo from '../../assets/perkbox_logo.png';

import { selectLoading } from '../../reducers/loading';
import { useStore } from '../../store';
import useFirebase from '../Firebase/useFirebase';
import { doRepopulateForm } from '../../reducers';
import YourChanges from '../YourChanges';
import {EndOfService} from "../EndOfService/EndOfService";

export const FormContext = React.createContext(false);

export const HomePage = ({ location: { search } }) => {
  const { state, dispatch } = useStore();
  const { global } = selectLoading(state);
  const firebase = useFirebase();

  const { value: isInEditMode, loading } = useAsync(async () => {
    if (search) {
      const { policyId } = parse(search.substring(1));
      if (policyId) {
        const result = await firebase.doGetPolicy(policyId);
        dispatch(doRepopulateForm(result));
        return true;
      }
    }
    return false;
  }, []);
  return (
    !isInEditMode ? <EndOfService/>
    :
    <FormContext.Provider value={isInEditMode}>
      <div className="App">
        <div className="content">
          {global ? <LoadingSpinner /> : null}
          <Container>
            <div className="logo-container">
              <img src={mobiruLogo} alt="logo" className="perkbox-logo" />
              <img src={perkboxLogo} alt="logo" className="perkbox-logo" />
            </div>
            {!isInEditMode && <ProgressIndicator />}
            {isInEditMode && (
              <div className="headerStyle">
                Your free mobile insurance changes
              </div>
            )}
            <Row>
              <Col lg="8">
                {isInEditMode && <YourChanges />}
                <YourDetails />
                <YourPhone />
              </Col>
              <Col lg="4">
                <YourQuote />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </FormContext.Provider>
  );
};
