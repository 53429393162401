import React from 'react';
import {css} from 'emotion';
import mobiruLogo from '../../assets/mobiru-gadget-logo.png';
import perkboxLogo from '../../assets/perkbox_logo.png';
import Container from "reactstrap/es/Container";

const bodyStyle = css`
  height: 100%;
  text-align: center;
  padding-top:50px;
`

const marginTop = css`
  margin-top: 10%;
`

const blackText = css`
  color: #404042;
  margin-top: 50px;
`

const linkStyle = css`
  text-decoration: none;
  color: #32ADE3!important;
  font-weight: bold;
  &:hover{
    text-decoration: none;
    color: #61caf9!important;
  }
`

export const EndOfService = () => {
  return (
    <div className={bodyStyle}>
      <Container>
        <div className="logo-container">
          <img src={mobiruLogo} alt="logo" className="perkbox-logo" />
          <img src={perkboxLogo} alt="logo" className="perkbox-logo" />
        </div>
        <h1 className={marginTop}>The Perkbox Mobile Phone Scheme is now closed</h1>
        <p className={blackText}>
          The Perkbox Mobile Phone Scheme is now closed, for further
          information please contact your Perkbox Account Manager
          directly on <br/><a className={linkStyle} href={'tel:020 8051 4555'}>020 8051 4555 </a>
           or email <a className={linkStyle} href={'mailto:help-me@perkbox.co.uk'}>help-me@perkbox.co.uk</a>
        </p>
      </Container>
    </div>
  );
}
