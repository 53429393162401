import { parse } from 'qs';
import { pick } from 'lodash';
import { camelKeys } from 'js-convert-case';

export function getUtmFields(location) {
  const searchObject = parse(location.search, { ignoreQueryPrefix: true });
  const utmKebabCase = pick(searchObject, [
    'utm-source',
    'utm-medium',
    'utm-campaign',
    'utm-content',
    'utm-term',
  ]);
  return camelKeys(utmKebabCase);
}
