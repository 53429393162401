import { find, get } from 'lodash';

const SET_DEVICES_AND_MAKES = 'SET_DEVICES_AND_MAKES';
const SET_MODELS = 'SET_MODELS';
const CHANGE_SELECTED_MAKE = 'CHANGE_SELECTED_MAKE';
const CHANGE_MODEL = 'CHANGE_MODEL';
const CHANGE_DEVICE_VALUE = 'CHANGE_DEVICE_VALUE';
const CHANGE_IMEI = 'CHANGE_IMEI';
const CHANGE_GADGET_VALIDATION_ENABLED = 'CHANGE_GADGET_VALIDATION_ENABLED';
const REPOPULATE_STATE = 'repopulate_state';
const mobileCode = 'MOBILE';
const appleCode = 'APPLE';

const INITIAL_STATE = {
  devices: null,
  deviceMakes: null,
  deviceModels: null,
  selectedDeviceIx: '',
  selectedMakeIx: '',
  isSelectedMakeIxValid: undefined,
  selectedModelIx: '',
  isImeiEnabled: false,
  isModelValid: false,
  imei: '',
  isImeiValid: false,
  isImeiNumberOfDigitsValid: false,
  deviceValue: '',
  isDeviceValueValid: false,
  isDeviceValueEntered: false,
  isValidationEnabled: false,
  model: '',
};

export const doSetModels = deviceModels => ({
  type: SET_MODELS,
  deviceModels,
});

function applySetModel(state, action) {
  const { deviceModels } = action;
  const { modelCode } = state;

  const model = modelCode;

  return {
    ...state,
    deviceModels,
    model,
  };
}

export const selectPhoneDetails = state => state.phoneDetails;

export const doSetDevicesAndMakes = (devices, deviceMakes) => ({
  type: SET_DEVICES_AND_MAKES,
  devices,
  deviceMakes,
});

export const doChangeSelectedMake = ix => ({
  type: CHANGE_SELECTED_MAKE,
  selectedMakeIx: ix,
});

export const doChangeModel = model => ({
  type: CHANGE_MODEL,
  model,
});

export const doChangeDeviceValue = deviceValue => ({
  type: CHANGE_DEVICE_VALUE,
  deviceValue,
});

export const doChangeImei = imei => ({
  type: CHANGE_IMEI,
  imei,
  isImeiValid: imei !== '',
});

export const doChangeValidationEnabled = isValidationEnabled => ({
  type: CHANGE_GADGET_VALIDATION_ENABLED,
  isValidationEnabled,
});

function applySetDevicesAndMakes(state, action) {
  const { devices, deviceMakes } = action;
  const { makeCode } = state;

  const mobileDeviceIx = devices.findIndex(d => d.code === mobileCode);
  const selectedDeviceIx = mobileDeviceIx === -1 ? 0 : mobileDeviceIx;

  const appleMakeIx = deviceMakes[selectedDeviceIx].findIndex(
    m => m.code === appleCode
  );

  const selectedMakeIx = makeCode
    ? deviceMakes[selectedDeviceIx].findIndex(m => m.code === makeCode)
    : undefined;

  const { code } = devices[selectedDeviceIx];
  const isImeiEnabled = code === mobileCode;

  return {
    ...state,
    devices,
    deviceMakes,
    isImeiEnabled,
    selectedDeviceIx,
    selectedMakeIx,
  };
}

function applyChangeSelectedMake(state, action) {
  const { selectedMakeIx } = action;
  const isSelectedMakeIxValid = selectedMakeIx !== undefined;

  return {
    ...state,
    selectedMakeIx,
    isSelectedMakeIxValid,
  };
}

function applyChangeModel(state, action) {
  const { model } = action;
  const isModelValid = model !== '';

  return {
    ...state,
    model,
    isModelValid,
  };
}

function applyChangeDeviceValue(state, action) {
  const { deviceValue } = action;
  const isDeviceValueValid = deviceValue <= 1200;
  const isDeviceValueEntered = deviceValue !== '';

  return {
    ...state,
    deviceValue,
    isDeviceValueValid,
    isDeviceValueEntered,
  };
}

function applyChangeImei(state, action) {
  const { imei, isImeiValid } = action;
  const isImeiNumberOfDigitsValid = isImeiValid ? imei.length === 15 : false;

  return {
    ...state,
    imei,
    isImeiValid,
    isImeiNumberOfDigitsValid,
  };
}

function applyChangeGadgetValidationEnabled(state, action) {
  const { isValidationEnabled } = action;
  return {
    ...state,
    isValidationEnabled,
  };
}

function repopulatePhoneDetails(state, action) {
  const { policy } = action.data;
  const { attributeList } = policy.genericInsuredObject[0];
  const deviceValue = get(
    find(attributeList, element => get(element, 'code') === 'MobileValue'),
    'value',
    ''
  );
  const makeCode = get(
    find(attributeList, element => get(element, 'code') === 'MobileMake'),
    'value',
    ''
  );
  const modelCode = get(
    find(attributeList, element => get(element, 'code') === 'MobileModel'),
    'value',
    ''
  );
  const imeiNumber = get(
    find(attributeList, element => get(element, 'code') === 'IMEINumber'),
    'value',
    ''
  );
  if (attributeList) {
    return {
      ...state,
      imei: imeiNumber,
      isImeiValid: true,
      isImeiNumberOfDigitsValid: true,
      deviceValue,
      isDeviceValueValid: true,
      isDeviceValueEntered: true,
      modelCode,
      isModelValid: true,
      makeCode,
      isSelectedMakeIxValid: true,
    };
  }
  return undefined;
}

function reducer(state = INITIAL_STATE, action) {
  const { type } = action;

  switch (type) {
    case SET_DEVICES_AND_MAKES:
      return applySetDevicesAndMakes(state, action);
    case SET_MODELS:
      return applySetModel(state, action);
    case CHANGE_SELECTED_MAKE:
      return applyChangeSelectedMake(state, action);
    case CHANGE_MODEL:
      return applyChangeModel(state, action);
    case CHANGE_DEVICE_VALUE:
      return applyChangeDeviceValue(state, action);
    case CHANGE_IMEI:
      return applyChangeImei(state, action);
    case CHANGE_GADGET_VALIDATION_ENABLED:
      return applyChangeGadgetValidationEnabled(state, action);
    case REPOPULATE_STATE:
      return repopulatePhoneDetails(state, action);
    default:
      return state;
  }
}

export default reducer;
