import React, { useContext } from 'react';
import { isNil, get } from 'lodash';
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Container,
  CardHeader,
  CardBody,
  FormFeedback,
} from 'reactstrap';
import { useStore } from '../../store';
import {
  doChangeDeviceValue,
  doChangeImei,
  doChangeModel,
  doChangeSelectedMake,
  selectPhoneDetails,
} from '../../reducers/phoneDetails';
import { FormContext } from '../HomePage/HomePage';

const YourPhone = () => {
  const { state, dispatch } = useStore();

  const phoneDetails = selectPhoneDetails(state);
  const isInEditMode = useContext(FormContext);

  const {
    devices,
    deviceMakes,
    selectedDeviceIx,
    selectedMakeIx,
    isSelectedMakeIxValid,
    model,
    selectedModelIx,
    isModelValid,
    imei,
    isImeiValid,
    deviceValue,
    isDeviceValueValid,
    isDeviceValueEntered,
    isValidationEnabled,
    isImeiEnabled,
    isImeiNumberOfDigitsValid,
    deviceModels,
  } = phoneDetails;
  const selectedMakeCode = get(deviceMakes, `[0][${selectedMakeIx}].code`, '');

  const otherValue = {
    value: 'Other',
    code: 'OTHERMODEL',
    parentCode: 'OTHERMAKE,MOBILEAPPLE,TABLETAPPLE,MOBILESAMSUNG,TABLETSAMSUNG',
    effectiveDate: new Date().toISOString(),
    system: 'POLICY',
  };
  const filteredDeviceModels = deviceModels
    ? deviceModels.filter(item => {
        if (item.parentCode === selectedMakeCode) {
          return item;
        }
        return '';
      })
    : [];
  const device = devices ? devices[0].value : '';

  const title = isInEditMode ? '3. Your Phone' : '2. Your Phone';

  return (
    <section>
      <Card className="custom-card">
        <CardHeader>{title}</CardHeader>
        <CardBody className="custom-card-body">
          <Form>
            <Container>
              <Row>
                <Col md={8}>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-item">Item</Label>
                        <Input
                          className="custom-input"
                          type="input"
                          name="input"
                          id="gadgets-to-cover-item"
                          value={device}
                          disabled
                          style={{ backgroundColor: 'white' }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-make">Make</Label>
                        <Input
                          className="custom-input"
                          type="select"
                          name="select"
                          id="gadgets-to-cover-make"
                          invalid={
                            isValidationEnabled && !isSelectedMakeIxValid
                          }
                          value={selectedMakeIx}
                          onChange={e =>
                            dispatch(doChangeSelectedMake(e.target.value))
                          }
                        >
                          {!selectedMakeIx && (
                            <option value={undefined} selected>
                              Select your device make
                            </option>
                          )}
                          {devices &&
                            deviceMakes[selectedDeviceIx].map(
                              ({ value, code }, ix) => (
                                <option
                                  key={`${selectedDeviceIx}${code}`}
                                  value={ix}
                                >
                                  {value}
                                </option>
                              )
                            )}
                        </Input>
                        <FormFeedback>Make is required</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-model">Model</Label>
                        <Input
                          className="custom-input"
                          type="select"
                          name="model"
                          id="gadgets-to-cover-model"
                          value={model}
                          invalid={isValidationEnabled && !isModelValid}
                          onChange={e =>
                            dispatch(doChangeModel(e.target.value))
                          }
                        >
                          {!model && (
                            <option value={undefined} selected>
                              Select your device model
                            </option>
                          )}
                          {deviceModels &&
                            filteredDeviceModels.map(({ value, code }) => (
                              <option key={code} value={code}>
                                {value}
                              </option>
                            ))}
                        </Input>
                        <FormFeedback>Model is required</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-value">Value</Label>
                        <CurrencyFormat
                          id="gadgets-to-cover-value"
                          value={deviceValue}
                          thousandSeparator
                          prefix="£"
                          placeholder="£"
                          allowNegative={false}
                          decimalScale={2}
                          onValueChange={e =>
                            dispatch(doChangeDeviceValue(e.value))
                          }
                          className={
                            isValidationEnabled &&
                            (!isDeviceValueValid || !isDeviceValueEntered)
                              ? 'form-control is-invalid'
                              : 'form-control custom-input'
                          }
                        />
                        <FormFeedback>
                          {!isDeviceValueEntered
                            ? 'Value is required'
                            : 'Value can not be more than £1200'}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div>
                        {isImeiEnabled && (
                          <FormGroup>
                            <Label>IMEI NUMBER</Label>
                            <Input
                              className="custom-input"
                              type="text"
                              name="imei"
                              id="gadgets-to-cover-imei"
                              placeholder="Enter IMEI Number"
                              value={imei}
                              invalid={
                                isValidationEnabled &&
                                (!isImeiValid || !isImeiNumberOfDigitsValid)
                              }
                              onChange={e =>
                                dispatch(doChangeImei(e.target.value))
                              }
                            />
                            <FormFeedback>
                              {!isImeiValid
                                ? 'IMEI is required'
                                : 'IMEI has to have 15 digits'}
                            </FormFeedback>
                          </FormGroup>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={4} className="bordered-column">
                  <i className="material-icons d-block text-center mb-2">
                    info
                  </i>
                  <p className="text-small">
                    <span className="font-weight-bold">VALUE </span>
                    <span className="color-grey">
                      - this is the original value at purchase. The maximum
                      value of the handset that can be covered is £1,200
                    </span>
                  </p>
                  <p className="text-small">
                    <span className="font-weight-bold">IMEI NUMBER </span>
                    <span className="color-grey">
                      - You are required to supply the IMEI number for mobile
                      phone you are insuring. We need these to be able to
                      validate any claim you may make in the future.
                    </span>
                  </p>
                  <p className="text-small color-grey">
                    Your IMEI number can be retrieved on your phone by dialing
                    *#06# (this will display a 15 digit number).
                  </p>
                </Col>
              </Row>
            </Container>
          </Form>
        </CardBody>
      </Card>
    </section>
  );
};

export default YourPhone;
