import moment from 'moment';

const SET_TITLES = 'SET_TITLES';
const CHANGE_SELECTED_TITLE = 'CHANGE_TITLE';
const CHANGE_FIRST_NAME = 'CHANGE_FIRST_NAME';
const CHANGE_LAST_NAME = 'CHANGE_LAST_NAME';
const CHANGE_EMAIL = 'CHANGE_EMAIL';
const CHANGE_PHONE = 'CHANGE_PHONE';
const CHANGE_USER_DETAILS_VALIDATION_ENABLED =
  'CHANGE_USER_DETAILS_VALIDATION_ENABLED';
const CHANGE_VALIDATION_CODE = 'CHANGE_VALIDATION_CODE';
const SET_VALIDATION_CODE_SERVER_ERROR = 'SET_VALIDATION_CODE_SERVER_ERROR';
const CHANGE_INCEPTION_DATE = 'CHANGE_INCEPTION_DATE';
const REPOPULATE_STATE = 'repopulate_state';

const VALIDATION_CODE_REQUIRED_MSG = 'Validation code is required';
const inceptionDateInPastMsg = 'Policy start date cannot be in the past';
const inceptionDateRequiredMsg = 'Policy start date is required';

const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return re.test(String(email).toLowerCase());
};

export const doSetTitles = titles => ({
  type: SET_TITLES,
  titles,
});

export const doChangeSelectedTitle = ix => ({
  type: CHANGE_SELECTED_TITLE,
  ix,
});

export const doChangeFirstName = firstName => ({
  type: CHANGE_FIRST_NAME,
  firstName,
});

export const doChangeLastName = lastName => ({
  type: CHANGE_LAST_NAME,
  lastName,
});

export const doChangeEmail = email => ({
  type: CHANGE_EMAIL,
  email,
});

export const doChangePhone = phone => ({
  type: CHANGE_PHONE,
  phone,
});

export const doChangeValidationEnabled = isValidationEnabled => ({
  type: CHANGE_USER_DETAILS_VALIDATION_ENABLED,
  isValidationEnabled,
});

export const doChangeValidationCode = validationCode => ({
  type: CHANGE_VALIDATION_CODE,
  validationCode,
});

export const doChangeInceptionDate = inceptionDate => ({
  type: CHANGE_INCEPTION_DATE,
  inceptionDate,
});

export const doSetValidationCodeServerError = validationCodeErrorMessage => ({
  type: SET_VALIDATION_CODE_SERVER_ERROR,
  validationCodeErrorMessage,
});

export const selectUserDetails = state => state.userDetails;

const INITIAL_STATE = {
  titles: null,
  isTitleValid: false,
  selectedTitleIx: undefined,
  firstName: '',
  isFirstNameValid: false,
  lastName: '',
  isLastNameValid: false,
  email: '',
  isEmailValid: false,
  isEmailEntered: false,
  phone: '',
  isPhoneValid: false,
  validationCode: '',
  isValidationCodeValid: false,
  isValidationEnabled: false,
  validationCodeErrorMessage: VALIDATION_CODE_REQUIRED_MSG,
  inceptionDate: moment().format('YYYY-MM-DD'),
  isInceptionDateValid: true,
  inceptionDateErrorMessage: inceptionDateRequiredMsg,
};

function applySetTitles(state, action) {
  const { titles } = action;

  const selectedTitleIx = state.titleCode
    ? titles.findIndex(t => t.code === state.titleCode)
    : undefined;
  return {
    ...state,
    selectedTitleIx,
    isTitleValid: selectedTitleIx !== undefined,
    titles,
  };
}

function applyChangeSelectedTitle(state, action) {
  const { ix } = action;
  return {
    ...state,
    selectedTitleIx: ix,
    isTitleValid: ix !== undefined,
  };
}

function applyChangeFirstName(state, action) {
  const { firstName } = action;
  return {
    ...state,
    firstName,
    isFirstNameValid: firstName !== '',
  };
}

function applyChangeLastName(state, action) {
  const { lastName } = action;
  return {
    ...state,
    lastName,
    isLastNameValid: lastName !== '',
  };
}

function applyChangeEmail(state, action) {
  const { email } = action;
  return {
    ...state,
    email,
    isEmailEntered: email !== '',
    isEmailValid: validateEmail(email),
  };
}

function applyChangePhone(state, action) {
  const { phone } = action;
  return {
    ...state,
    phone,
    isPhoneValid: phone !== '',
  };
}

function applyChangeUserDetailsValidationEnabled(state, action) {
  const { isValidationEnabled } = action;

  return {
    ...state,
    isValidationEnabled,
  };
}
function applyChangeValidationCode(state, action) {
  const { validationCode } = action;

  const validationCodeEmpty = validationCode === '';
  const { validationCodeErrorMessage } = state;

  const errorMessage = validationCodeEmpty
    ? VALIDATION_CODE_REQUIRED_MSG
    : validationCodeErrorMessage;
  return {
    ...state,
    validationCode,
    isValidationCodeValid: !validationCodeEmpty,
    validationCodeErrorMessage: errorMessage,
  };
}

function applyChangeInceptionDate(state, action) {
  const { inceptionDate } = action;
  const validationInceptionDateEmpty = inceptionDate === '';
  const now = new Date();
  const validationInceptionDateInPast = moment(inceptionDate).isBefore(
    now,
    'day'
  );
  const { inceptionDateErrorMessage } = state;
  const errorMessage = validationInceptionDateInPast
    ? inceptionDateInPastMsg
    : inceptionDateRequiredMsg;
  const isInceptionDateValid =
    !validationInceptionDateEmpty && !validationInceptionDateInPast;
  return {
    ...state,
    inceptionDate,
    isInceptionDateValid,
    inceptionDateErrorMessage: errorMessage,
  };
}

function applySetValidationCodeServerError(state, action) {
  const { validationCodeErrorMessage } = action;

  return {
    ...state,
    isValidationCodeValid: false,
    validationCodeErrorMessage,
  };
}

function repopulateUserDetails(state, action) {
  const { policy } = action.data;
  const { policyHolder } = policy;
  return {
    ...state,
    firstName: policyHolder.firstName,
    isFirstNameValid: true,
    lastName: policyHolder.lastName,
    isLastNameValid: true,
    email: policyHolder.emailAddress,
    isEmailValid: true,
    isEmailEntered: true,
    inceptionDate: policy.coverStartDate,
    isInceptionDateValid: true,
    titleCode: policyHolder.title.code,
    validationCode: policy.externalReference,
    isValidationCodeValid: true,
    phone: policyHolder.telephone,
    isPhoneValid: true,
  };
}

function reducer(state = INITIAL_STATE, action) {
  const { type } = action;
  switch (type) {
    case SET_TITLES:
      return applySetTitles(state, action);
    case CHANGE_SELECTED_TITLE:
      return applyChangeSelectedTitle(state, action);
    case CHANGE_FIRST_NAME:
      return applyChangeFirstName(state, action);
    case CHANGE_LAST_NAME:
      return applyChangeLastName(state, action);
    case CHANGE_EMAIL:
      return applyChangeEmail(state, action);
    case CHANGE_PHONE:
      return applyChangePhone(state, action);
    case CHANGE_USER_DETAILS_VALIDATION_ENABLED:
      return applyChangeUserDetailsValidationEnabled(state, action);
    case CHANGE_VALIDATION_CODE:
      return applyChangeValidationCode(state, action);
    case CHANGE_INCEPTION_DATE:
      return applyChangeInceptionDate(state, action);
    case SET_VALIDATION_CODE_SERVER_ERROR:
      return applySetValidationCodeServerError(state, action);
    case REPOPULATE_STATE:
      return repopulateUserDetails(state, action);
    default:
      return state;
  }
}

export default reducer;
