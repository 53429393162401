import moment from 'moment';

const CHANGE_MTA_START_DATE = 'CHANGE_MTA_START_DATE';
const CHANGE_MTA_REASON = 'CHANGE_MTA_REASON';
const CHANGE_DETAILS_VALIDATION_ENABLED =
  'CHANGE_ADDRESS_DETAILS_VALIDATION_ENABLED';
const SET_MTA_REASONS = 'SET_MTA_REASONS';
const REPOPULATE_STATE = 'repopulate_state';
const SET_MTA_DATE_SERVER_ERROR = 'SET_MTA_DATE_SERVER_ERROR';

export const selectChangeDetails = state => state.changeDetails;

const INITIAL_STATE = {
  mtaStartDate: '',
  isMtaStartDateValid: false,
  isMtaStartDateEntered: false,
  mtaReason: '',
  isMtaReasonValid: false,
  isValidationEnabled: false,
  mtaReasons: null,
  coverStartDate: null,
  coverEndDate: null,
  policyReference: null,
  mtaDateErrorMessage: '',
};

export const doSetMtaReasons = mtaReasons => ({
  type: SET_MTA_REASONS,
  mtaReasons,
});

export const doChangeValidationEnabled = isValidationEnabled => ({
  type: CHANGE_DETAILS_VALIDATION_ENABLED,
  isValidationEnabled,
});

export const doChangeMtaStartDate = mtaStartDate => ({
  type: CHANGE_MTA_START_DATE,
  mtaStartDate,
});

export const doChangeMtaReason = mtaReason => ({
  type: CHANGE_MTA_REASON,
  mtaReason,
});

export const doSetMtaDateServerError = mtaDateErrorMessage => ({
  type: SET_MTA_DATE_SERVER_ERROR,
  mtaDateErrorMessage,
});

function applyChangeMtaStartDate(state, action) {
  const { mtaStartDate } = action;
  const isMtaStartDateEntered = mtaStartDate !== '';
  const isMtaStartDateValid =
    mtaStartDate &&
    moment(mtaStartDate).isBetween(
      state.coverStartDate,
      state.coverEndDate,
      null,
      '[)'
    );
  let mtaDateErrorMessage = '';
  if (!isMtaStartDateEntered) {
    mtaDateErrorMessage = 'Date is required';
  } else if (!isMtaStartDateValid) {
    mtaDateErrorMessage = 'Date has to be between policy start and end date';
  }

  return {
    ...state,
    mtaStartDate,
    isMtaStartDateEntered,
    isMtaStartDateValid,
    mtaDateErrorMessage,
  };
}

function applyChangeMtaReason(state, action) {
  const { mtaReason } = action;

  return {
    ...state,
    mtaReason,
    isMtaReasonValid: mtaReason !== '',
  };
}

function applySetMtaReasons(state, action) {
  const { mtaReasons } = action;

  return {
    ...state,
    mtaReasons,
  };
}

function applyChangeAddressDetailsValidationEnabled(state, action) {
  const { isValidationEnabled } = action;

  return {
    ...state,
    isValidationEnabled,
  };
}

function applySetMtaDateServerError(state, action) {
  const { mtaDateErrorMessage } = action;

  return {
    ...state,
    isMtaStartDateValid: false,
    mtaDateErrorMessage,
  };
}

function repopulateChangeDetails(state, action) {
  const { policy } = action.data;
  const { coverStartDate, coverEndDate, policyNumber } = policy;
  return {
    ...state,
    coverStartDate,
    coverEndDate,
    policyReference: policyNumber,
  };
}

function reducer(state = INITIAL_STATE, action) {
  const { type } = action;
  switch (type) {
    case CHANGE_MTA_START_DATE:
      return applyChangeMtaStartDate(state, action);
    case CHANGE_MTA_REASON:
      return applyChangeMtaReason(state, action);
    case CHANGE_DETAILS_VALIDATION_ENABLED:
      return applyChangeAddressDetailsValidationEnabled(state, action);
    case SET_MTA_REASONS:
      return applySetMtaReasons(state, action);
    case REPOPULATE_STATE:
      return repopulateChangeDetails(state, action);
    case SET_MTA_DATE_SERVER_ERROR:
      return applySetMtaDateServerError(state, action);
    default:
      return state;
  }
}

export default reducer;
