const CHANGE_GLOBAL_LOADING = 'CHANGE_GLOBAL_LOADING';

export const selectLoading = state => state.loading;

export const doSetGlobalLoading = isLoading => ({
  type: CHANGE_GLOBAL_LOADING,
  global: isLoading,
});

const INITIAL_STATE = {
  global: false,
};

function applyChangeGlobalLoading(state, action) {
  const { global } = action;

  return {
    ...state,
    global,
  };
}

function reducer(state = INITIAL_STATE, action) {
  const { type } = action;
  switch (type) {
    case CHANGE_GLOBAL_LOADING:
      return applyChangeGlobalLoading(state, action);

    default:
      return state;
  }
}

export default reducer;
