import React from 'react';
import { Spinner } from 'reactstrap';

import './loading-overlay.scss';

const LoadingSpinner = () => (
  <div className="loading-overlay">
    <div className="loading-overlay__container">
      <Spinner type="grow" className="loading-overlay__spinner" />
      <Spinner type="grow" className="loading-overlay__spinner" />
      <Spinner type="grow" className="loading-overlay__spinner" />
    </div>
  </div>
);

export default LoadingSpinner;
