import combineReducers from './combineReducers';
import userDetails from './userDetails';
import addressDetails from './addressDetails';
import phoneDetails from './phoneDetails';
import loading from './loading';
import changeDetails from './changeDetails';

const REPOPULATE_STATE = 'repopulate_state';

export const doRepopulateForm = data => ({
  type: REPOPULATE_STATE,
  data,
});

const reducers = combineReducers({
  userDetails,
  addressDetails,
  phoneDetails,
  changeDetails,
  loading,
});

export default reducers;
