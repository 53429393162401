import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Alert,
} from 'reactstrap';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { PERKBOX_MOBILE } from '@insync/common/constants/products';
import { useStore } from '../../store';
import {
  doChangeValidationEnabled as doChangeUserDetailsValidationEnabled,
  doSetValidationCodeServerError,
  selectUserDetails,
} from '../../reducers/userDetails';
import {
  selectAddressDetails,
  doChangeValidationEnabled as doChangeAddressDetailsValidationEnabled,
} from '../../reducers/addressDetails';

import {
  selectPhoneDetails,
  doChangeValidationEnabled as doChangePhoneDetailsValidationEnabled,
} from '../../reducers/phoneDetails';

import { useFirebase } from '../Firebase';
import { isAddressDetailsValid } from '../../validation/addressDetails';
import { isUserDetailsValid } from '../../validation/userDetails';
import { isGadgetDetailsValid } from '../../validation/gadgetDetails';
import * as ROUTES from '../../constants/routes';
import Toba from './toba-doc.pdf';
import Policy from './policy-new-doc.pdf';
import { doSetGlobalLoading } from '../../reducers/loading';
import { getUtmFields } from '../../utils/get-utm-fields';
import {
  selectChangeDetails,
  doChangeValidationEnabled as doChangeChangeDetailsValidationEnabled,
  doSetMtaDateServerError,
} from '../../reducers/changeDetails';
import { isChangeDetailsValid } from '../../validation/changeDetails';
import { FormContext } from '../HomePage/HomePage';

const YourQuote = ({ history, location }) => {
  const { state, dispatch } = useStore();
  const firebase = useFirebase();
  const isInEditMode = useContext(FormContext);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [enableValidation, setEnableValidation] = useState(false);
  const [showSaveError, setShowSaveError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const userDetails = selectUserDetails(state);
  const addressDetails = selectAddressDetails(state);
  const phoneDetails = selectPhoneDetails(state);
  const changeDetails = selectChangeDetails(state);

  useEffect(() => {
    let timer;
    if (showSaveError) {
      timer = setTimeout(() => {
        setShowSaveError(false);
      }, 10000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showSaveError]);

  const handleComplete = async event => {
    event.preventDefault();

    if (
      !isAddressDetailsValid(addressDetails) ||
      !isUserDetailsValid(userDetails) ||
      !isGadgetDetailsValid(phoneDetails) ||
      !isConfirmed
    ) {
      dispatch(doChangeUserDetailsValidationEnabled(true));
      dispatch(doChangeAddressDetailsValidationEnabled(true));
      dispatch(doChangePhoneDetailsValidationEnabled(true));
      dispatch(doChangeChangeDetailsValidationEnabled(true));
      setEnableValidation(true);
      return;
    }

    if (isInEditMode && !isChangeDetailsValid(changeDetails)) {
      dispatch(doChangeChangeDetailsValidationEnabled(true));
      setEnableValidation(true);
      return;
    }

    const utm = getUtmFields(location);

    const {
      titles,
      selectedTitleIx,
      firstName,
      lastName,
      email,
      phone,
      validationCode,
      inceptionDate,
    } = userDetails;

    const { code: titleCode, value: titleValue } = titles[selectedTitleIx];

    const {
      postcode,
      addressLine1,
      addressLine2,
      county,
      city,
    } = addressDetails;

    const {
      imei,
      model,
      devices,
      deviceMakes,
      selectedDeviceIx,
      selectedMakeIx,
      deviceValue,
    } = phoneDetails;

    const selectedDevice = devices[selectedDeviceIx];
    const selectedMake = deviceMakes[selectedDeviceIx][selectedMakeIx];

    const policyDevice = {
      model,
      item: {
        code: selectedDevice.code,
      },
      make: {
        code: selectedMake.code,
      },
      value: deviceValue,
    };

    if (imei) {
      policyDevice.imeiNumber = imei;
    }

    const { mtaStartDate, mtaReason, policyReference } = changeDetails;

    const savePolicyRequest = {
      title: {
        code: titleCode,
        description: titleValue,
      },
      firstName,
      lastName,
      email,
      phone,
      validationCode,
      inceptionDate,
      productName: PERKBOX_MOBILE,
      address: {
        postcode,
        addressLine1,
        addressLine2,
        county,
        city,
      },
      devices: [policyDevice],
      utm,
    };

    try {
      dispatch(doSetGlobalLoading(true));
      if (isInEditMode) {
        await firebase.doCreateMTAPerkbox({
          request: savePolicyRequest,
          policyReference,
          mtaStartDate: new Date(mtaStartDate).getTime(),
          mtaReason,
        });
      } else {
        await firebase.doSaveQuotePerkbox({ request: savePolicyRequest });
      }
      const searchParams = `?portal-link=${isInEditMode}${isInEditMode ? '&utm_content=mtaconfirm':''}`
      window.location=`${ROUTES.SUCCESS}/?portal-link=${isInEditMode}${isInEditMode ? '&utm_content=mtaconfirm':''}`
      // history.push({
      //   pathname: ROUTES.SUCCESS,
      //   search: searchParams,
      // });
      dispatch(doSetGlobalLoading(false));
    } catch (err) {
      dispatch(doSetGlobalLoading(false));
      let validationShown = false;
      if (err.details && err.details.code && err.details.message) {
        const { details } = err;
        if (
          details.code === 'validation-code-invalid' ||
          details.code === 'validation-code-used'
        ) {
          validationShown = true;
          dispatch(doChangeUserDetailsValidationEnabled(true));
          dispatch(doSetValidationCodeServerError(details.message));
        }
        if (details.code === 'invalid-mta-date') {
          validationShown = true;
          dispatch(doChangeChangeDetailsValidationEnabled(true));
          dispatch(doSetMtaDateServerError(details.message));
        }
      }

      if (!validationShown) {
        setShowSaveError(true);
        setErrorMessage(get(err, 'details.message'));
      }
    }
  };

  const title = isInEditMode
    ? '4. Terms and Conditions'
    : '3. Terms and Conditions';

  return (
    <section className="sticky-component">
      <Card className="custom-card">
        <CardHeader>{title}</CardHeader>
        <CardBody className="custom-card-body">
          <Form>
            <h5>PLEASE CONFIRM THE FOLLOWING:</h5>
            <ul className="text-small">
              <li>You are a permanent resident of the United Kingdom</li>
              <li>
                You have proof of ownership for each device you wish to insure
                (we exclude devices purchased from online auctions)
              </li>
              <li>
                You are not aware of any previous incidents that may lead to a
                future claim
              </li>
              <li>
                You have read the{' '}
                <a href={Policy} target="_blank" rel="noopener noreferrer">
                  Policy Wording
                </a>
              </li>
              <li>
                You have read our{' '}
                <a href={Toba} target="_blank" rel="noopener noreferrer">
                  Terms & Conditions
                </a>
              </li>
              <li>
                You understand that no advice has been provided regarding the
                suitability of this contract for your use. However, you believe
                that this product meets your needs now and in the future and
                that any loss, damage or theft of the identified device is
                covered by the insurance
              </li>
              <li>
                You are aware cover will commence 14 days after you have
                received confirmation from us that your mobile phone has been
                registered.
              </li>
            </ul>
            <Row form>
              <div className="ml-auto mr-auto mt-2 mb-4">
                <FormGroup
                  check
                  className="text-center d-flex flex-column pl-0 pr-0"
                >
                  <Input
                    type="checkbox"
                    onChange={e => setIsConfirmed(e.target.checked)}
                    value={isConfirmed}
                    invalid={enableValidation && !isConfirmed}
                    className="custom-checkbox checkbox-column mb-2"
                    id="confirm-checkbox"
                  />
                  <Label
                    for="confirm-checkbox"
                    className="text-uppercase"
                    check
                  >
                    Confirm
                  </Label>
                  <FormFeedback>Please confirm required fields</FormFeedback>
                </FormGroup>
              </div>
            </Row>
            <Row form>
              <Col md="12">
                <Button
                  className="perkbox-complete-btn btn-block rounded-0 d-block"
                  onClick={handleComplete}
                >
                  Click to Complete
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Alert color="danger" isOpen={showSaveError}>
        {errorMessage ||
          'An error has occurred while saving your policy. Please try again later.'}
      </Alert>
    </section>
  );
};

export default withRouter(YourQuote);
