export function isUserDetailsValid(userDetailsState) {
  const {
    isFirstNameValid,
    isLastNameValid,
    isEmailValid,
    isEmailEntered,
    isPhoneValid,
    isValidationCodeValid,
    isInceptionDateValid,
    isTitleValid,
  } = userDetailsState;

  return (
    isFirstNameValid &&
    isLastNameValid &&
    isEmailValid &&
    isEmailEntered &&
    isPhoneValid &&
    isValidationCodeValid &&
    isInceptionDateValid &&
    isTitleValid
  );
}
