import { pick, pickBy, keys } from 'lodash';

const phoneDetailsFieldNames = [
  'deviceValue',
  'imei',
  'selectedMakeIx',
  'model',
];

const userDetailsFieldNames = [
  'email',
  'firstName',
  'lastName',
  'phone',
  'selectedTitleIx',
  'validationCode',
];

const addressDetailsFieldNames = [
  'addressLine1',
  'addressLine2',
  'city',
  'county',
  'postcode',
];

export const calculateProgress = (
  phoneDetails,
  userDetails,
  addressDetails
) => {
  const phoneDetailsFields = pick(phoneDetails, phoneDetailsFieldNames);
  const userDetailsFields = pick(userDetails, userDetailsFieldNames);
  const addressDetailsFields = pick(addressDetails, addressDetailsFieldNames);
  const phoneValidFields = pickBy(
    phoneDetailsFields,
    value => value !== undefined && value !== ''
  );
  const userValidFields = pickBy(
    userDetailsFields,
    value => value !== undefined && value !== ''
  );
  const addressValidFields = pickBy(
    addressDetailsFields,
    value => value !== undefined && value !== ''
  );
  const numberOfAllFields =
    keys(phoneDetailsFields).length +
    keys(userDetailsFields).length +
    keys(addressDetailsFields).length;
  const numberOfValidFields =
    keys(phoneValidFields).length +
    keys(userValidFields).length +
    keys(addressValidFields).length;
  const result = numberOfValidFields / numberOfAllFields;
  return (result * 100).toFixed(0);
};
