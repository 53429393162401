import React from 'react';
import { css } from 'emotion';
import { Row, Col } from 'reactstrap';

import { selectUserDetails } from '../../reducers/userDetails';
import { selectAddressDetails } from '../../reducers/addressDetails';
import { selectPhoneDetails } from '../../reducers/phoneDetails';
import { selectChangeDetails } from '../../reducers/changeDetails';
import { useStore } from '../../store';
import { calculateProgress } from '../../utils/calculate-progress';

const containerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const headerStyle = css`
  font-size: 23px;
  text-transform: uppercase;
  color: #32ade3;
`;

const contentStyle = css`
  color: #32ade3;
  @media (max-width: 800px) {
    text-align: right;
    font-size: 0.8rem;
  }
`;

const percentageStyle = css`
  font-size: 27px;
`;

export const ProgressIndicator = () => {
  const { state } = useStore();
  const progressValue = calculateProgress(
    selectPhoneDetails(state),
    selectUserDetails(state),
    selectAddressDetails(state),
    selectChangeDetails(state)
  );
  return (
    <Row>
      <Col lg="12">
        <div className={containerStyle}>
          <div className={headerStyle}>Your free mobile insurance</div>
          <div className={contentStyle}>
            <span className={percentageStyle}>{progressValue}%</span> COMPLETE
          </div>
        </div>
      </Col>
    </Row>
  );
};
