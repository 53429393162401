import React, { useReducer, useState, useEffect } from 'react';
import StoreContext from './context';
import reducer from '../reducers';
import useStore from './useStore';

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // no reducer will explicitly handle this, used to initialize
    // each reducer's initial state
    dispatch({ type: '@init' });
    setIsLoaded(true);
  }, []);

  const value = { state, dispatch };

  return (
    <StoreContext.Provider value={value}>
      {isLoaded ? children : null}
    </StoreContext.Provider>
  );
};

export { useStore };

export default StoreProvider;
