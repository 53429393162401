import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { PERKBOX_MOBILE } from '@insync/common/constants/products';

import SuccessPage from '../SuccessPage';
import { HomePage } from '../HomePage/HomePage';

import '../../styles/index.scss';
import { useFirebase } from '../Firebase';
import { useStore } from '../../store';
import { doSetTitles } from '../../reducers/userDetails';
import { doSetDevicesAndMakes, doSetModels } from '../../reducers/phoneDetails';
import * as ROUTES from '../../constants/routes';
import { doSetGlobalLoading } from '../../reducers/loading';
import { doSetMtaReasons } from '../../reducers/changeDetails';
import {EndOfService} from "../EndOfService/EndOfService";

// postcssFocusWithin.process(`
//     .input-border-div[focus-within] label {
//     background-color: yellow;
//     height: 500px;
//   }
//
//   .input-border-div:focus-within label {
//     background-color: yellow;
//     height: 500px;
//   }`);

export function App() {
  const firebase = useFirebase();
  const { dispatch } = useStore();

  useEffect(() => {
    async function fetchLookups() {
      dispatch(doSetGlobalLoading(true));
      const lookups = await firebase.doGetLookups(PERKBOX_MOBILE);

      const {
        titles: lookupTitles,
        devices: lookupDevices,
        makesByDevice: lookupMakesByDevice,
        deviceModels: lookupDeviceModels,
        mtaReason: lookupMtaReason,
      } = lookups;

      const newDeviceMakes = [];
      for (const { code } of lookupDevices) {
        newDeviceMakes.push(lookupMakesByDevice[code]);
      }

      dispatch(doSetTitles(lookupTitles));
      dispatch(doSetDevicesAndMakes(lookupDevices, newDeviceMakes));
      dispatch(doSetModels(lookupDeviceModels));
      dispatch(doSetMtaReasons(lookupMtaReason));

      dispatch(doSetGlobalLoading(false));
    }

    fetchLookups();
  }, [dispatch, firebase]);

  return (
    <Router>
      <Switch>
        <Route path={ROUTES.HOME} exact component={HomePage} />
        <Route path={ROUTES.SUCCESS} component={SuccessPage} />
      </Switch>
    </Router>
  );
}
