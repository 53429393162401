export function isGadgetDetailsValid(gadgetDetailsState) {
  const {
    isModelValid,
    isDeviceValueValid,
    isDeviceValueEntered,
    isImeiValid,
    isImeiEnabled,
    isImeiNumberOfDigitsValid,
  } = gadgetDetailsState;

  return (
    isModelValid &&
    isDeviceValueValid && isDeviceValueEntered &&
    (!isImeiEnabled || (isImeiValid && isImeiNumberOfDigitsValid))
  );
}
