import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import {
  doChangeMtaReason,
  doChangeMtaStartDate,
  selectChangeDetails,
} from '../../reducers/changeDetails';
import { useStore } from '../../store';

const YourChanges = () => {
  const { state, dispatch } = useStore();
  const changeDetails = selectChangeDetails(state);
  const {
    mtaStartDate,
    isMtaStartDateValid,
    isMtaStartDateEntered,
    mtaReason,
    isMtaReasonValid,
    isValidationEnabled,
    mtaReasons,
    mtaDateErrorMessage,
  } = changeDetails;

  return (
    <section>
      <Card className="custom-card">
        <CardHeader>1. Your Changes</CardHeader>
        <CardBody className="custom-card-body">
          <Form>
            <Container>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="gadgets-to-cover-first-name">
                      Date your change should be applied
                    </Label>
                    <div className="input-border-div">
                      <Input
                        className="custom-input"
                        type="date"
                        name="mtaStartDate"
                        id="gadgets-to-cover-first-name"
                        invalid={
                          isValidationEnabled &&
                          (!isMtaStartDateValid || !isMtaStartDateEntered)
                        }
                        value={mtaStartDate}
                        onChange={e =>
                          dispatch(doChangeMtaStartDate(e.target.value))
                        }
                      />
                      <FormFeedback>{mtaDateErrorMessage}</FormFeedback>
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="gadgets-to-cover-first-name">
                      Reason for change
                    </Label>
                    <div className="input-border-div">
                      <Input
                        className="custom-input"
                        type="select"
                        name="mtaReason"
                        id="gadgets-to-cover-first-name"
                        value={mtaReason}
                        invalid={isValidationEnabled && !isMtaReasonValid}
                        onChange={e =>
                          dispatch(doChangeMtaReason(e.target.value))
                        }
                      >
                        <option value={undefined} selected>
                          Select the reason for your change
                        </option>
                        {mtaReasons &&
                          mtaReasons.map(({ value, code }) => (
                            <option key={code} value={value}>
                              {value}
                            </option>
                          ))}
                      </Input>
                      <FormFeedback>Reason for change is required</FormFeedback>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </Form>
        </CardBody>
      </Card>
    </section>
  );
};

export default YourChanges;
