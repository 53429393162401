import React from 'react';
import { Container, Row } from 'reactstrap';
import { parse } from 'qs';
import Logo from '../../assets/mobiru-gadget-logo.png';
import Footer from '../Footer';

const SuccessPage = ({ location: { search } }) => {
  let showUrl = false;
  if (search) {
    showUrl = parse(search.substring(1))['portal-link'] === 'true';
  }
  return (
    <section className="success-page-container">
      <Container className="h-100">
        <Row>
          <div className="success-page text-center">
            <img className="perkbox-logo" src={Logo} alt="Mobiru" />
            <h1 className="success-page__title w-100 text-center">
              THANK YOU <br /> FOR REGISTERING
            </h1>
            <ul className="success-page__list list-unstyled text-center">
              <li>
                We have received your device details and your mobile phone is
                now insured!
              </li>
              <li>
                We will send you an email shortly to let you know how to access
                your documents via our Customer Portal.
              </li>
              <li>
                In the meantime, if you have any questions please feel free to
                get in touch via the chat below.
              </li>
            </ul>
            {showUrl && (
              <h3 className="portal-link-dsc">
                Click
                <a
                  href={`${process.env.REACT_APP_PERKBOX_PORTAL_URL}/my-policies`}
                  className="portal-link"
                >
                  {' '}
                  here{' '}
                </a>
                to go back to customer portal
              </h3>
            )}
          </div>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default SuccessPage;
