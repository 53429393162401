import React, { useState, useContext } from 'react';
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  Button,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
  FormFeedback,
} from 'reactstrap';
import { css, cx } from 'emotion';
import moment from 'moment';
import { useFirebase } from '../Firebase';

import { useStore } from '../../store';
import {
  selectUserDetails,
  doChangeSelectedTitle,
  doChangeFirstName,
  doChangeLastName,
  doChangeEmail,
  doChangePhone,
  doChangeValidationCode,
  doChangeInceptionDate,
} from '../../reducers/userDetails';
import {
  doChangeAddressLine1,
  doChangeAddressLine2,
  doChangeCity,
  doChangeCounty,
  doChangePostcode,
  doSetDetailsFromSearchResult,
  doSetSearchResults,
  selectAddressDetails,
} from '../../reducers/addressDetails';

import LoadingSpinner from '../LoadingSpinner';
import { FormContext } from '../HomePage/HomePage';

const Details = () => {
  const { state, dispatch } = useStore();
  const firebase = useFirebase();
  const [postcodeLoading, setPostcodeLoading] = useState(false);
  const isInEditMode = useContext(FormContext);

  const buttonStyle = css`
    font-weight: bold;
    margin-left: 10px;
    border: 0px solid;
    border-radius: 3px !important;
    background: linear-gradient(
      to right,
      rgba(50, 174, 227, 1) 0%,
      rgba(29, 123, 60, 1) 100%
    );
    :hover {
      background-image: linear-gradient(100deg, #1c91c2, #106a14);
      box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
    }
    :disabled {
      box-shadow: none;
      background: linear-gradient(
        to right,
        rgba(50, 174, 227, 1) 0%,
        rgba(29, 123, 60, 1) 100%
      );
    }
  `;

  const {
    titles,
    selectedTitleIx,
    isTitleValid,
    firstName,
    isFirstNameValid,
    lastName,
    isLastNameValid,
    email,
    isEmailEntered,
    isEmailValid,
    phone,
    isPhoneValid,
    validationCode,
    isValidationCodeValid,
    inceptionDate,
    isInceptionDateValid,
    isValidationEnabled: isUserDetailsValidationEnabled,
    validationCodeErrorMessage,
    inceptionDateErrorMessage,
  } = selectUserDetails(state);

  const {
    postcode,
    isPostcodeValid,
    addressLine1,
    isAddressLine1Valid,
    addressLine2,
    isAddressLine2Valid,
    county,
    city,
    isCityValid,
    isCountyValid,
    searchResults,
    isValidationEnabled: isAddressDetailsValidationEnabled,
  } = selectAddressDetails(state);

  const handleFindAddress = async event => {
    event.preventDefault();
    if (!postcode) {
      return;
    }

    try {
      setPostcodeLoading(true);
      const results = await firebase.doGetAddressAutocomplete(postcode);
      if (results) {
        dispatch(doSetSearchResults(results));
      }
      setPostcodeLoading(false);
    } catch (err) {
      setPostcodeLoading(false);
      console.error(err);
    }
  };

  const handleAddressSelect = async (event, itemId) => {
    event.preventDefault();
    dispatch(doSetSearchResults(null));
    try {
      setPostcodeLoading(true);
      const {
        line1,
        line2,
        province,
        city: detailsCity,
      } = await firebase.doGetAddressDetails(itemId);

      const searchResultDetails = {
        postcode,
        addressLine1: line1,
        addressLine2: line2,
        city: detailsCity,
      };

      if (province && province.code) {
        searchResultDetails.county = province.code;
      }

      dispatch(doSetDetailsFromSearchResult(searchResultDetails));
      setPostcodeLoading(false);
    } catch (err) {
      console.error(err);
      setPostcodeLoading(false);
    }
  };

  const title = isInEditMode ? '2. Your Details' : '1. Your Details';

  return (
    <section>
      <Card className="custom-card">
        <CardHeader>{title}</CardHeader>
        <CardBody className="custom-card-body">
          <Form>
            <Container>
              <Row>
                <Col md="7">
                  <Row form>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-title">Title</Label>
                        <div>
                          <Input
                            className="custom-input"
                            type="select"
                            name="selectedTitleIx"
                            id="gadgets-to-cover-title"
                            value={selectedTitleIx}
                            onChange={e =>
                              dispatch(doChangeSelectedTitle(e.target.value))
                            }
                            invalid={
                              isUserDetailsValidationEnabled && !isTitleValid
                            }
                          >
                            {!selectedTitleIx && (
                              <option value={undefined} selected>
                                Title
                              </option>
                            )}
                            {titles &&
                              titles.map(({ value, code }, ix) => (
                                <option key={code} value={ix}>
                                  {value}
                                </option>
                              ))}
                          </Input>
                          <FormFeedback>Title is required</FormFeedback>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-first-name">
                          First Name
                        </Label>
                        <div className="input-border-div">
                          <Input
                            className="custom-input"
                            type="text"
                            name="firstName"
                            id="gadgets-to-cover-first-name"
                            value={firstName}
                            invalid={
                              isUserDetailsValidationEnabled &&
                              !isFirstNameValid
                            }
                            onChange={e =>
                              dispatch(doChangeFirstName(e.target.value))
                            }
                          />
                          <FormFeedback>First Name is required</FormFeedback>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={5}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-last-name">
                          Last Name
                        </Label>
                        <Input
                          className="custom-input"
                          type="text"
                          name="lastName"
                          id="gadgets-to-cover-last-name"
                          value={lastName}
                          invalid={
                            isUserDetailsValidationEnabled && !isLastNameValid
                          }
                          onChange={e =>
                            dispatch(doChangeLastName(e.target.value))
                          }
                        />
                        <FormFeedback>Last Name is required</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={7}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-email">Work Email</Label>
                        <Input
                          className="custom-input"
                          type="email"
                          name="email"
                          id="gadgets-to-cover-email"
                          value={email}
                          invalid={
                            isUserDetailsValidationEnabled &&
                            (!isEmailValid || !isEmailEntered)
                          }
                          onChange={e =>
                            dispatch(doChangeEmail(e.target.value))
                          }
                        />
                        <FormFeedback>
                          {!isEmailEntered
                            ? 'Email is required'
                            : 'You have to provide a valid email'}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={5}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-phone-number">
                          Phone Number
                        </Label>
                        <Input
                          className="custom-input"
                          type="text"
                          name="phone"
                          id="gadgets-to-cover-phone-number"
                          value={phone}
                          invalid={
                            isUserDetailsValidationEnabled && !isPhoneValid
                          }
                          onChange={e =>
                            dispatch(doChangePhone(e.target.value))
                          }
                        />
                        <FormFeedback>Phone is required</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={7}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-validation-code">
                          Validation code
                        </Label>
                        <Input
                          className="custom-input"
                          type="text"
                          name="validationCode"
                          id="gadgets-to-cover-validation-code"
                          disabled={isInEditMode}
                          value={validationCode}
                          invalid={
                            isUserDetailsValidationEnabled &&
                            !isValidationCodeValid
                          }
                          onChange={e =>
                            dispatch(doChangeValidationCode(e.target.value))
                          }
                        />
                        <FormFeedback>
                          {validationCodeErrorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={5}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-validation-code">
                          Policy Start Date
                        </Label>
                        <Input
                          className="custom-input"
                          type="date"
                          name="inceptionDate"
                          id="gadgets-to-cover-validation-code"
                          disabled
                          value={
                            inceptionDate
                              ? moment(inceptionDate).format('YYYY-MM-DD')
                              : ''
                          }
                          invalid={
                            isUserDetailsValidationEnabled &&
                            !isInceptionDateValid
                          }
                          onChange={e =>
                            dispatch(doChangeInceptionDate(e.target.value))
                          }
                        />
                        <FormFeedback>{inceptionDateErrorMessage}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col
                  md="5"
                  className="bordered-column loading-overlay-relative"
                >
                  <Row form>
                    {postcodeLoading ? <LoadingSpinner /> : null}
                    <Col md={12}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-postcode">Postcode</Label>
                        <InputGroup>
                          <Input
                            className="custom-input"
                            type="text"
                            name="postcode"
                            id="gadgets-to-cover-postcode"
                            value={postcode}
                            invalid={
                              isAddressDetailsValidationEnabled &&
                              !isPostcodeValid
                            }
                            onChange={e =>
                              dispatch(doChangePostcode(e.target.value))
                            }
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              color="secondary"
                              className={buttonStyle}
                              onClick={handleFindAddress}
                            >
                              Find Address
                            </Button>
                          </InputGroupAddon>
                          <FormFeedback>Postcode is required</FormFeedback>
                        </InputGroup>
                      </FormGroup>
                      {searchResults && (
                        <ListGroup className="postcode-group">
                          {searchResults.map(({ searchResult, itemId }) => (
                            <ListGroupItem
                              key={itemId}
                              className="postcode-group__item"
                              onClick={e => handleAddressSelect(e, itemId)}
                            >
                              {searchResult}
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      )}
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-address-1">
                          Address Line 1
                        </Label>
                        <Input
                          className="custom-input"
                          type="text"
                          name="addressLine1"
                          id="gadgets-to-cover-address-1"
                          value={addressLine1}
                          invalid={
                            isAddressDetailsValidationEnabled &&
                            !isAddressLine1Valid
                          }
                          onChange={e =>
                            dispatch(doChangeAddressLine1(e.target.value))
                          }
                        />
                        <FormFeedback>Address Line 1 is required</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-address-2">
                          Address Line 2
                        </Label>
                        <Input
                          className="custom-input"
                          type="text"
                          name="addressLine2"
                          id="gadgets-to-cover-address-2"
                          value={addressLine2}
                          onChange={e =>
                            dispatch(doChangeAddressLine2(e.target.value))
                          }
                        />
                        <FormFeedback>Address Line 2 is required</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-city">City</Label>
                        <Input
                          className="custom-input"
                          type="text"
                          name="city"
                          id="gadgets-to-cover-city"
                          value={city}
                          invalid={
                            isAddressDetailsValidationEnabled && !isCityValid
                          }
                          onChange={e => dispatch(doChangeCity(e.target.value))}
                        />
                        <FormFeedback>City is required</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="gadgets-to-cover-county">County</Label>
                        <Input
                          className="custom-input"
                          type="text"
                          name="county"
                          id="gadgets-to-cover-county"
                          value={county}
                          invalid={
                            isAddressDetailsValidationEnabled && !isCountyValid
                          }
                          onChange={e =>
                            dispatch(doChangeCounty(e.target.value))
                          }
                        />
                        <FormFeedback>County is required</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Form>
        </CardBody>
      </Card>
    </section>
  );
};

export default Details;
